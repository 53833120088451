// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../styleguide/components/Link/A.res.js";
import * as Url from "../../utils/Url.res.js";
import * as Form from "../../styleguide/forms/Form/Form.res.js";
import * as Hooks from "../../libs/Hooks.res.js";
import * as Button from "../../styleguide/components/Button/Button.res.js";
import * as $$Promise from "../../bindings/Promise.res.js";
import * as IconChat from "../../styleguide/icons/IconChat.res.js";
import * as IconPhone from "../../styleguide/icons/IconPhone.res.js";
import * as TextField from "../../styleguide/forms/TextField/TextField.res.js";
import * as Cloudinary from "../../libs/Cloudinary.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Routes_Page from "../../routes/common/Routes_Page.res.js";
import * as Api_Provider from "../../api/providers/Api_Provider.res.js";
import * as SentryLogger from "../../loggers/SentryLogger.res.js";
import * as Routes_Provider from "../../routes/common/Routes_Provider.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as CreateProviderData from "./CreateProviderData.res.js";
import * as Global from "Client/common/global";
import * as DashboardCreateProviderScss from "./DashboardCreateProvider.scss";

var css = DashboardCreateProviderScss;

var initialState_input = CreateProviderData.Input.empty();

var initialState = {
  isSubmitted: false,
  input: initialState_input,
  results: undefined,
  serverError: undefined
};

function DashboardCreateProvider$default(props) {
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action === "object") {
            if (action.TAG === "ServerError") {
              return {
                      TAG: "UpdateWithSideEffects",
                      _0: {
                        isSubmitted: state.isSubmitted,
                        input: state.input,
                        results: state.results,
                        serverError: action._0
                      },
                      _1: (function (param) {
                          param.dispatch("AfterSubmit");
                        })
                    };
            } else {
              return {
                      TAG: "Update",
                      _0: {
                        isSubmitted: state.isSubmitted,
                        input: action._0,
                        results: state.results,
                        serverError: state.serverError
                      }
                    };
            }
          }
          switch (action) {
            case "Validate" :
                return {
                        TAG: "Update",
                        _0: {
                          isSubmitted: state.isSubmitted,
                          input: state.input,
                          results: CreateProviderData.Validate.all(state.input),
                          serverError: state.serverError
                        }
                      };
            case "Submit" :
                return {
                        TAG: "UpdateWithSideEffects",
                        _0: {
                          isSubmitted: state.isSubmitted,
                          input: state.input,
                          results: CreateProviderData.Validate.all(state.input),
                          serverError: state.serverError
                        },
                        _1: (function (param) {
                            var dispatch = param.dispatch;
                            var state = param.state;
                            if (CreateProviderData.Validate.valid(Belt_Option.getExn(state.results))) {
                              return $$Promise.wait(Api_Provider.create(state.input), (function (x) {
                                            if (x.TAG === "Ok") {
                                              return Url.visit(Routes_Provider.Dashboard.edit(x._0.slug));
                                            }
                                            SentryLogger.error1({
                                                  rootModule: "DashboardCreateProvider",
                                                  subModulePath: /* [] */0,
                                                  value: "default",
                                                  fullPath: "DashboardCreateProvider.default"
                                                }, "CreateProvider::Error", [
                                                  "Error",
                                                  x._0
                                                ]);
                                            dispatch({
                                                  TAG: "ServerError",
                                                  _0: "Something went wrong"
                                                });
                                          }));
                            } else {
                              return dispatch("AfterSubmit");
                            }
                          })
                      };
            case "AfterSubmit" :
                return {
                        TAG: "Update",
                        _0: {
                          isSubmitted: false,
                          input: state.input,
                          results: state.results,
                          serverError: state.serverError
                        }
                      };
            case "BeforeSubmit" :
                return {
                        TAG: "UpdateWithSideEffects",
                        _0: {
                          isSubmitted: true,
                          input: state.input,
                          results: state.results,
                          serverError: state.serverError
                        },
                        _1: (function (param) {
                            param.dispatch("Submit");
                          })
                      };
            
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx("h1", {
                                    children: "Create New Provider",
                                    className: css.h1
                                  }),
                              JsxRuntime.jsx("h2", {
                                    children: "To create a new provider, first enter the desired provider name below.",
                                    className: css.h2
                                  }),
                              JsxRuntime.jsxs(Form.make, {
                                    onSubmit: (function (param) {
                                        dispatch("BeforeSubmit");
                                      }),
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: JsxRuntime.jsx(TextField.make, {
                                                  id: "createProvider-form--name",
                                                  value: state.input.name,
                                                  placeholder: "Provider Name",
                                                  status: Belt_Option.map(state.results, (function (x) {
                                                          if (x.name.TAG === "Ok") {
                                                            return "Valid";
                                                          } else {
                                                            return "Error";
                                                          }
                                                        })),
                                                  onChange: (function ($$event) {
                                                      dispatch({
                                                            TAG: "Update",
                                                            _0: {
                                                              name: $$event.target.value
                                                            }
                                                          });
                                                    })
                                                }),
                                            className: css.formRow
                                          }),
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx(Button.make, {
                                                    size: "MD",
                                                    color: "Primary",
                                                    margin: "RightMargin",
                                                    busy: state.isSubmitted,
                                                    submit: true,
                                                    children: "Create Provider"
                                                  }),
                                              JsxRuntime.jsx(Button.make, {
                                                    size: "MD",
                                                    color: "Gray",
                                                    onClick: (function (_e) {
                                                        Url.visit(Routes_Provider.Dashboard.index);
                                                      }),
                                                    children: "Cancel"
                                                  })
                                            ],
                                            className: css.submitRow
                                          }),
                                      state.isSubmitted ? JsxRuntime.jsx("div", {
                                              children: JsxRuntime.jsx("p", {
                                                    children: "Thanks! Your request has been submitted.",
                                                    className: css.submitMessage
                                                  }),
                                              className: css.submitRow
                                            }) : null
                                    ]
                                  })
                            ],
                            className: css.leftContent
                          }),
                      JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsxs("div", {
                                  children: [
                                    JsxRuntime.jsxs("div", {
                                          children: [
                                            JsxRuntime.jsx("img", {
                                                  className: css.imgPerson,
                                                  height: "100",
                                                  src: Cloudinary.imageUrl("f_auto/v1615844503/static/joel-st.germain.png"),
                                                  width: "100"
                                                }),
                                            JsxRuntime.jsxs("div", {
                                                  children: [
                                                    JsxRuntime.jsx("p", {
                                                          children: "Joel St. Germain, CEO",
                                                          className: css.contactPersonName
                                                        }),
                                                    JsxRuntime.jsx("p", {
                                                          children: "Having trouble? Please contact our Concierge team.",
                                                          className: css.contactPersonDesc
                                                        })
                                                  ],
                                                  className: css.contactPersonDescContainer
                                                })
                                          ],
                                          className: css.contactPersonContainer
                                        }),
                                    JsxRuntime.jsx("div", {
                                          className: css.divider
                                        }),
                                    JsxRuntime.jsxs("div", {
                                          children: [
                                            JsxRuntime.jsx(IconPhone.make, {
                                                  size: "SM",
                                                  color: "Gray",
                                                  className: css.iconMargin
                                                }),
                                            JsxRuntime.jsx(A.make, {
                                                  href: Global.DCContactNumberHRef,
                                                  children: Global.DCContactNumber
                                                })
                                          ],
                                          className: css.contactPersonDescData
                                        }),
                                    JsxRuntime.jsx("div", {
                                          className: css.divider
                                        }),
                                    JsxRuntime.jsxs("div", {
                                          children: [
                                            JsxRuntime.jsx(IconChat.make, {
                                                  size: "SM",
                                                  color: "Gray",
                                                  className: css.iconMargin
                                                }),
                                            JsxRuntime.jsx(A.make, {
                                                  href: Routes_Page.contact,
                                                  children: " Contact us"
                                                })
                                          ],
                                          className: css.contactPersonDescData
                                        }),
                                    JsxRuntime.jsx("div", {
                                          className: css.divider
                                        })
                                  ],
                                  className: css.personContainerDesktop
                                }),
                            className: css.rightContent
                          })
                    ],
                    className: css.pageContent
                  }),
              className: css.pageContainer
            });
}

var Input;

var Validate;

var ValidationResult;

var $$default = DashboardCreateProvider$default;

export {
  css ,
  Input ,
  Validate ,
  ValidationResult ,
  initialState ,
  $$default as default,
}
/* css Not a pure module */
